@import "./variables.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "./bootswatch.scss";

@import "~lightbox2/dist/css/lightbox.css";

.container {
    padding: 2rem;
}

form > div {
    margin-bottom: 1rem;
}

form > div.label-element {
    margin-bottom: inherit;
}

.upload-item {
    width: 100px;
    height: 100px;
    position: relative;
}

.remove-upload-item {
    background-color: red;
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
}
.loader {
    top: calc(50% - 5em);
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.5em solid rgba(4, 0, 0, 0.2);
    border-right: 0.5em solid rgba(4, 0, 0, 0.2);
    border-bottom: 0.5em solid rgba(4, 0, 0, 0.2);
    border-left: 0.5em solid #040000;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.outerLoader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.gallery-wishlist-icon {
    opacity: 1;
    cursor: pointer;
}

.image-title,
.gallery-title:not(.outside) {
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    pointer-events: none;

    & > h3 {
        color: white;
        text-shadow: 2px 2px 4px #000000;
    }

    &.top {
        top: 0;
    }
    &.bottom {
        bottom: 0;
    }
    &.middle {
        top: 50%;
        transform: translateY(-50%);
    }
}

.gallery-title.outside {
    display: flex;
    justify-content: space-between;
    padding: 16px;
}

.wishlist-icon {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    width: 100%;
}

.scroller {
    overflow: auto;
    white-space: nowrap;

    & > .scroll-item {
        position: relative;
        display: inline-block;
        width: 80%;
        vertical-align: top;
    }
}

strong,
b {
    font-weight: 800 !important;
}

.store-locator-element .results-container {
    position: relative;
}

.store-locator-element .results {
    position: absolute;
    z-index: 2000;
    background: white;
    border: 1px black solid;
    width: 100%;
}

.store-locator-element a {
    cursor: pointer;
}
